import type Node from '../models/node.js';
import edit from '../services/edit.js';
import * as  Util from '../util/util.js';
import { project } from '../services/app.js';
import { get } from 'svelte/store';

export function setParent(node: Node, newParent: Node, keepWorldTransform = false, index: number) {
    if (node === newParent) return;

    const oldParent = node.getParent();

    let n = newParent;
    while (n) {
        const parent = n.getParent();
        if (parent == node) {
            // Will cause cycle -- resolve by moving n to node's parent
            setParent(n, oldParent, true, oldParent?.getChildren()?.indexOf(node));
            break;
        }

        n = parent;
    }

    if (oldParent) {
        edit.remove(oldParent.childIds, node.id.get());
    }

    if (newParent) {
        edit.insert(newParent.childIds, node.id.get(), index);
    }

    const worldPosition = node.getWorldPosition();
    const worldRotation = node.getWorldRotation();
    const worldScale = node.getWorldScale();

    edit.set(node.parentId.value, newParent?.id.get() ?? '');

    if (keepWorldTransform) {
        node.setWorldPosition(worldPosition);
        node.setWorldRotation(worldRotation);
        node.setWorldScale(worldScale);
    }
}

export function insertAbove(node: Node, target: Node) {
    if (node === target) return;

    edit.startBatch();

    const targetParent = target.getParent();
    if (targetParent) {
        setParent(node, targetParent, true, target.getChildIndex());
    } else {
        setParent(node, null, true, 0);
        const nodes = get(get(project).scene).nodes;
        Util.remove(nodes, node);
        nodes.splice(nodes.indexOf(target), 0, node);
    }

    updateSceneList();

    edit.endBatch();
}

export function insertBelow(node: Node, target: Node) {
    if (node === target) return;

    edit.startBatch();

    const targetParent = target.getParent();
    if (targetParent) {
        setParent(node, targetParent, true, target.getChildIndex() + 1);
    } else {
        setParent(node, null, true, 0);
        const nodes = get(get(project).scene).nodes;
        Util.remove(nodes, node);
        nodes.splice(nodes.indexOf(target) + 1, 0, node);
    }

    updateSceneList();

    edit.endBatch();
}

export function insertChild(node: Node, target: Node) {
    if (node === target) return;

    edit.startBatch();
    setParent(node, target, true, target.childIds.length);
    updateSceneList();
    get(project).reselect();
    edit.endBatch();
}

export function updateSceneList() {
    const scene = get(project).scene;
    const nodes = get(scene).nodes;
    const newNodes = nodes.filter(n => !n.parentId.get()).flatMap(n => [n, ...n.getChildren(true)]);

    edit.do({
        action: () => scene.update(v => {
            v.nodes = newNodes;
            return v;
        }),
        undoAction: () => scene.update(v => {
            v.nodes = nodes;
            return v;
        })
    });
}