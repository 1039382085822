<script lang="ts">
    import { onMount } from "svelte";
    import FatalError from "../components/fatalError.svelte"
    import Nav from "../components/nav.svelte"
    import { app } from "../services/app";
    import user from '../services/user.js'
    import { smallScreen } from "../util/screen";
    import External from "./external.svelte";

    export let requireSignIn = false;
    export let addBottomPadding = true;
    export let dark = false;

    const studioUrl = "https://www.virtualmaker.dev";

    onMount(() => {
        if (requireSignIn && !user.signedIn) {
            setTimeout(() => { throw new Error("You must be signed in to use Virtual Maker.") }, 0);
        }
    });

    let page: HTMLDivElement;
    let bg: HTMLDivElement;
    window.addEventListener('scroll', () => {
        if (page && bg) {
            // console.log("-" + (bg.clientHeight * (window.screenTop / window.innerHeight)) + "px");
            // bg.style.top = "-" + ((bg.clientHeight * window.scrollY) / window.innerHeight) + "px";
            // const top = -window.scrollY * (bg.clientHeight - window.innerHeight) / (page.clientHeight - window.innerHeight);
            // console.log(page.clientHeight);
            // console.log(bg.clientHeight);
            // console.log(window.innerHeight);
            // console.log(window.scrollY);
            // console.log(top);
            // bg.style.top = top + "px";
        }
    });

    $: fakeFullScreen = app.fakeFullScreen;

</script>

<FatalError />

<div bind:this={page} class="flex col align-center w-100 sz-16 page" class:page-dark={dark} style="padding-bottom: {addBottomPadding ? '500px' : '0px'}">
    <div bind:this={bg} class:bg={!dark} class:darkbg={dark}></div>
    {#if !$fakeFullScreen}
        <div class:p-10={$smallScreen} class:p-20={!$smallScreen} class="w-100 max-page-width navbg">
            <Nav />
        </div>
    {/if}
    <div class="flex col align-center w-100" style="min-height: 85vh">
        {#if !requireSignIn || user.signedIn}
            <slot />
        {/if}
    </div>
    {#if !$fakeFullScreen}
        <div class="flex gap-20 max-page-width p-20 sz-14 align-center">
            <img src="logo.svg" style="width: 20px" alt="logo" />
            <p>© 2024 <External source="copyright" target="virtualmaker" href={studioUrl}>Virtual Maker Corporation</External></p>
            <a href="/terms">Terms</a>
            <a href="/privacy">Privacy</a>
        </div>
    {/if}
</div>

<style>
    .page {
        /* background: linear-gradient(120.67deg, #28659e 11.3%, #a370b3 80.51%); */
        min-height: 100vh;
        background: transparent;
        color: rgb(9, 56, 115);
    }

    .page-dark {
        color: white;
    }

    .bg {
        /* background: linear-gradient(120.67deg, #28659e 11.3%, #a370b3 80.51%); */
        background-image: url("bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        width: 100vw;
        height: 200vh;
        z-index: -2;
    }

    .darkbg {
        background: linear-gradient(120.67deg, #202020 11.3%, #222222 80.51%);
        position: fixed;
        width: 100vw;
        height: 200vh;
        z-index: -2;
    }
</style>