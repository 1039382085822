import * as BABYLON from '@babylonjs/core';
import Scene from "./scene.js"
import Node, { nodeFactories } from "./node.js"
import { deserializeArray } from '../util/serializable.js';
import { writable, Writable, get } from 'svelte/store';
import { Action, ActionManager, IsSenderCondition } from '../services/actionManager.js';
import { userStore } from '../services/store.js';
import { array } from "../util/array.js";
import type TransitionManager from "../services/transitionManager.js";
import { v4 as uuidv4 } from 'uuid';
import { app } from '../services/app.js';
import * as Util from '../util/util.js'
import edit from '../services/edit.js'
import { compat } from './compat.js';
import paywall from '../services/paywall.js'
import api from '../services/api.js'
import { Script } from './script.js';
import { ActionProperty, NodeProperty, StringProperty } from './property.js';
import * as NodeUtil from '../util/nodeUtil.js';

export interface EditorLayout {
    leftPanelSize: number,
    rightPanelSize: number
}

const defaultEditorLayout: EditorLayout = {
    leftPanelSize: 350,
    rightPanelSize: 400
}

export enum ProjectType {
    Standard = 0,
    Pro = 1
}

export default class Project {
    public version = 0.9;
    public type = ProjectType.Standard;
    public id = uuidv4();
    public name = new StringProperty("New Project");
    public thumbnail = writable('');
    public scenes = array<Scene>();
    public scene: Writable<Scene> = writable();
    public selected = array<Node>();
    public hovered: Writable<Node> = writable();
    public editorLayout = writable(defaultEditorLayout);
    public actionManager = new ActionManager();
    public meshAdded = new BABYLON.Observable<BABYLON.AbstractMesh>();
    public scripts = array<Script>();
    public template: string;
    public templateName: string;
    public templateStep = writable(0);
    public templateData: any = {};
    public babylonScene: BABYLON.Scene;

    private scriptsToDelete = new Set<Script>();
    public data: any;

    constructor(public transitionManager: TransitionManager) {}

    public async save() {
        if (new URL(window.location.href).searchParams.get('nosave')) {
            return;
        }

        if (app.takeScreenshot) {
            await app.takeScreenshot();
        }

        console.log("Saving project");

        const data = {
            version: this.version,
            type: this.type,
            id: this.id,
            name: this.name.serialize(),
            thumbnail: get(this.thumbnail),
            sceneId: get(this.scene).id.get(),
            scenes: this.scenes.map(s => s.serialize()),
            editorLayout: get(this.editorLayout),
            scripts: this.scripts.map(s => s.serialize()),
            actions: this.actionManager.serialize(),
            template: this.template,
            templateName: this.templateName,
            templateStep: get(this.templateStep),
            templateData: this.templateData
        };

        this.data = data;

        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: "text/json;charset=utf-8" });
        await userStore.save(blob, `project-${this.id}.json`);
        for (const script of this.scripts.get()) {
            await script.save();
        }

        for (const script of this.scriptsToDelete) {
            script.delete();
        }

        this.scriptsToDelete.clear();
    }

    public deserialize(data: any, startFromBeginning: boolean) {
        if (this.version !== data.version) {
            console.log(`Upgrading project from ${data.version || 'unversioned'} to ${this.version}`);
            data = compat.upgrade(data);
        }

        this.data = data;
        this.id = data.id || uuidv4();
        this.type = data.type || ProjectType.Standard;
        this.name.deserialize(data.name);
        this.thumbnail.set(data.thumbnail);
        this.scenes.set(deserializeArray(data.scenes, () => new Scene()));
        if (startFromBeginning) {
            this.scene.set(this.scenes.get()[0]);
        } else {
            this.scene.set(this.scenes.find(s => s.id.get() === data.sceneId));
        }

        this.deselect();
        this.transitionToScene(get(this.scene).id.get());
        this.editorLayout.set(data.editorLayout || defaultEditorLayout);
        data.scripts = data.scripts || [];
        this.scripts.set(deserializeArray(data.scripts, () => new Script(this.id)));
        this.actionManager.deserialize(data.actions);
        this.template = data.template || data.tutorial;
        this.templateName = data.templateName;
        this.templateStep.set(data.templateStep || 0);
        this.templateData = data.templateData || {};

        for (const script of this.scripts.get()) {
            this.transitionManager.waitForPromise(script.load());
        }
    }

    public findUniqueSceneName(prefix: string = "New Scene") {
        return this.findUniqueName(prefix, this.scenes.map(s => s.name.get()));
    }

    public findUniqueNodeName(prefix: string, scene: Scene = null): string {
        if (!scene) {
            scene = get(this.scene);
        }

        return this.findUniqueName(prefix, scene.nodes.map(n => n.name.get()));
    }

    public duplicateScene(scene: Scene) {
        const name = prompt("Duplicate scene name", this.findUniqueSceneName(scene.name.get()));
        if (name) {
            edit.startBatch();
            const serialized = get(this.scene).serialize();
            const newScene = new Scene();
            const id = newScene.id.get();
            serialized.nodes = [];
            newScene.deserialize(serialized);
            newScene.name.set(name);
            newScene.id.set(id);
            newScene.thumbnail.set('');
            scene.nodes.filter(n => !n.parentId.get()).map(n => this.duplicateNode(n, newScene, true));

            const idx = this.scenes.length;
            edit.do({
                action: () => this.addScene(newScene, idx),
                undoAction: () => this.deleteScene(newScene)
            });

            edit.endBatch();
        }
    }

    public duplicateNode(node: Node, scene: Scene = null, addToEnd = false, parentId = '') {
        edit.startBatch();

        // Duplicate all properties
        const serialized = node.serialize();
        const factory = nodeFactories.get(serialized.type);
        const newNode = factory();
        const id = newNode.id.get();
        newNode.deserialize(serialized);

        // Change name and ID
        newNode.name.set(this.findUniqueNodeName(node.name.get(), scene));
        newNode.id.set(id);

        // Update parent ID
        if (!parentId && node.parentId.get()) {
            parentId = node.parentId.get();
        }

        if (!scene) {
            scene = get(this.scene);
        }

        newNode.parentId.set(parentId);

        // Insert into parent's child array
        if (parentId) {
            const parent = scene.nodes.find(n => n.id.get() === parentId);
            let index = parent.childIds.length;
            if (node.parentId.get() === parentId) {
                index = parent.childIds.indexOf(node.id.get()) + 1;
            }

            edit.insert(parent.childIds, id, index);
        }

        // Duplicate actions
        for (const [k, v] of Object.entries(node)) {
            if (v instanceof ActionProperty) {
                if (v.hasAction) {
                    const duplicateAction = new Action();
                    duplicateAction.deserialize(v.action.serialize());
                    (duplicateAction.conditions[0] as IsSenderCondition).senderId = id;
                    for (const step of duplicateAction.steps.get()) {
                        for (const prop of Object.values(step)) {
                            if (prop instanceof NodeProperty) {
                                if (prop.getId() === node.id.get()) {
                                    prop.setId(id)
                                }
                            }
                        }
                    }
                    this.actionManager.register(duplicateAction);
                }
            }
        }

        const nodes = scene.nodes;
        edit.insert(nodes, newNode,
            addToEnd ? nodes.length : nodes.indexOf(node) + 1);

        // Duplicate children
        newNode.childIds.clear();
        for (const child of node.getChildren()) {
            this.duplicateNode(child, scene, addToEnd, id);
        }

        // Update scene nodes to updated hierachy
        NodeUtil.updateSceneList();

        edit.endBatch();
        return newNode;
    }

    public async duplicateNodes(nodes: Node[]) {
        edit.startBatch();
        const newNodes = nodes.map(n => this.duplicateNode(n));
        await Promise.all(newNodes.map(n => n.loadedPromise));
        edit.setArray(this.selected, newNodes);
        edit.endBatch();
        return newNodes;
    }

    private deleteScene(scene: Scene) {
        const idx = this.scenes.indexOf(scene);
        Util.remove(this.scenes, scene);
        scene.onDelete();

        if (scene === get(this.scene)) {
            this.selectSceneAtIndex(idx);
        }
    }

    private addScene(scene: Scene, idx: number) {
        this.scenes.splice(idx, 0, scene);
        this.transitionToScene(scene.id.get());
    }

    public deleteCurrentScene() {
        const scene = get(this.scene);
        const idx = this.scenes.indexOf(scene);

        edit.do({
            action: () => this.deleteScene(scene),
            undoAction: () => this.addScene(scene, idx)
        });
    }

    public selectSceneAtIndex(idx: number) {
        this.transitionToScene(this.scenes.get()[Math.min(idx, this.scenes.length - 1)].id.get());
    }

    public createNewScene() {
        let name = this.findUniqueSceneName();
        name = prompt('Scene Name', name);

        if (name) {
            const newScene = new Scene(name);
            const idx = this.scenes.length;
            edit.do({
                action: () => this.addScene(newScene, idx),
                undoAction: () => this.deleteScene(newScene)
            });
        }
    }

    public createFirstScene() {
        let name = this.findUniqueSceneName();
        const newScene = new Scene(name);
        this.scene.set(newScene);
        this.addScene(newScene, 0);
        edit.restartIdleTimer();
    }

    public deleteNode(node: Node) {
        edit.startBatch();

        for (const child of node.getChildren()) {
            this.deleteNode(child);
        }

        edit.remove(this.selected, node);
        edit.removeAndUpdate(this.scene, get(this.scene).nodes, node);

        if (node.parentId.get()) {
            edit.remove(node.getParent().childIds, node.id.get());
        }

        for (const [k, v] of Object.entries(node)) {
            if (v instanceof ActionProperty) {
                if (v.hasAction) {
                    edit.remove(this.actionManager.actions, v.action);
                }
            }
        }

        edit.endBatch();
    }

    public deleteSelectedNodes() {
        edit.startBatch();
        for (const node of get(this.selected).slice() ) {
            this.deleteNode(node);
        }

        edit.endBatch();
    }

    public deselect(markEdit = false) {
        if (markEdit) {
            edit.setArray(this.selected, []);
        } else {
            this.selected.clear();
        }
    }

    public async transitionToScene(id: string) {
        this.hovered.set(null);
        this.transitionManager.enqueueTransition(() => {
            this.scene.set(this.scenes.find(s => s.id.get() === id));
            this.deselect();
        });
    }

    public getNode(id: string) {
        return get(this.scene).nodes.find(n => n.id.get() === id);
    }

    public getNodeByName(name: string) {
        return get(this.scene).nodes.find(n => n.name.get() === name);
    }

    public async insertNode(node: Node) {
        edit.startBatch();
        const scene = this.scene;
        edit.pushAndUpdate(scene, get(scene).nodes, node);
        this.deselect(true);
        if (node.position.isUnset) {
            // End batch after nodeBehavior places the node
        } else {
            await node.loadedPromise;
            edit.setArray(this.selected, [node]);
            edit.endBatch();
        }
    }

    public async saveAs(newName: string) {
        await edit.setIdle();

        this.name.set(newName)
        this.id = uuidv4();

        for (const script of this.scripts.get()) {
            script.refreshFilename(this.id);
        }

        await this.save();

        window.location.href = `/edit?e=${this.id}`;
    }

    public async delete() {
        await userStore.delete(`project-${this.id}.json`);
        const thumbnail = get(this.thumbnail);
        if (thumbnail) {
            await userStore.delete(thumbnail);
        }

        for (const scene of this.scenes.get()) {
            await scene.onDelete();
        }

        for (const script of this.scripts.get()) {
            await script.delete();
        }
    }

    public async publish() {
        await edit.setIdle();
        await api.publish(`project-${this.id}`);
        window.open(`/play?p=${this.id}`);
    }

    public async upgradeToPro() {
        if (this.type === ProjectType.Pro) {
            return true;
        }

        if (await paywall(true)) {
            // FUTURE: prompt to ensure the user wants to upgrade the project to pro
            this.type = ProjectType.Pro;
            return true;
        }

        return false;
    }

    public createScript() {
        const script = new Script(this.id);
        script.name.set(this.findUniqueName('Script', this.scripts.map(s => s.name.get())));

        edit.do({
            action: () => this.scripts.push(script),
            undoAction: () => this._deleteScript(script)
        });

        return script;
    }

    public deleteScript(script: Script) {
        const idx = this.scripts.indexOf(script);
        edit.do({
            action: () => this._deleteScript(script),
            undoAction: () => {
                this.scripts.splice(idx, 0, script);
                this.scriptsToDelete.delete(script);
            }
        });
    }

    private _deleteScript(script: Script) {
        Util.remove(this.scripts, script);
        this.scriptsToDelete.add(script);
    }

    public findUniqueName(prefix: string, collection: string[]) {
        const match = prefix.match(/(.*) (-?\d+)/);
        let num = 0;
        if (match) {
            prefix = match[1];
            num = Number.parseInt(match[2]);
        }

        return this._findUniqueName(prefix, collection, num);
    }

    private _findUniqueName(prefix: string, collection: string[], i = 0) {
        while (true) {
            if (i === 0) {
                if (collection.includes(prefix)) {
                    i = 2; // Skip 1
                } else if (collection.includes(`${prefix} 0`)) {
                    i = 1;
                } else if (collection.includes(`${prefix} -1`)) {
                    return prefix + ' 0'; // Add the zero if negative exists
                } else {
                    return prefix;
                }
            } else {
                const candidate = `${prefix} ${i}`;
                if (collection.includes(candidate)) {
                    i++;
                } else {
                    return candidate;
                }
            }
        }
    }

    public isSelected(node: Node) {
        return get(this.selected).includes(node);
    }

    public multiSelectNode(node: Node) {
        if (this.isSelected(node)) {
            this.removeNodeFromSelection(node);
        } else {
            this.addNodeToSelection(node);
        }
    }

    public addNodeToSelection(node: Node) {
        if (this.selected.includes(node)) {
            return;
        } else {
            edit.push(this.selected, node);
        }
    }

    public removeNodeFromSelection(node: Node) {
        edit.remove(this.selected, node);
    }

    public selectNode(node: Node) {
        edit.setArray(this.selected, [node]);
    }

    public reselect() {
        edit.setArray(this.selected, this.selected.get());
    }

    public nextTemplateStep() {
        edit.set(this.templateStep, get(this.templateStep) + 1);
    }

    public previousTemplateStep() {
        edit.set(this.templateStep, get(this.templateStep) - 1);
    }

    public goToTemplateStep(step: number) {
        edit.set(this.templateStep, step);
    }
}