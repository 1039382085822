<script lang="ts">
    import insights from '../services/insights';

    export let target: string;
    export let href: string;
    export let cls = '';
    export let source: string;

    function onClick() {
       insights.trackEvent('visit-external', { target, source });
    }
</script>

<!-- svelte-ignore security-anchor-rel-noreferrer -->
<a on:click={onClick} {href} class={cls} referrerpolicy="origin" target='_blank' rel='external'><slot/></a>

