import { writable } from "svelte/store";

export const smallScreen = writable(false);
export const mediumScreen = writable(false);
export const largeScreen = writable(false);
function resize() {
    smallScreen.set(window.innerWidth < 1000);
    mediumScreen.set(window.innerWidth < 1300);
    largeScreen.set(window.innerWidth >= 1300);
}

window.addEventListener('resize', resize);
resize();