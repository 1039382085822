import * as BABYLON from '@babylonjs/core';
import * as Util from '../util/util.js'
import { BoolProperty, StringProperty, NodeProperty, OptionProperty, QuaternionProperty, RangeProperty, Vector3Property, ModelAnimationProperty } from '../models/property.js';
import type Node from './node.js'
import { deserializeProperties, Serializable, serializeProperties } from '../util/serializable.js';
import { get } from 'svelte/store';
import { EaseCurves } from '../api/animationApi.js';
import api from '../api/api.js';
import type ObjectApi from '../api/objectApi.js';

export enum AnimationType {
    None = 'none',
    Spin = 'spin',
    Orbit = 'orbit',
    Bounce = 'bounce',
    Path = 'path',
    FromModel = 'from-model'
}

export abstract class Animation implements Serializable {
    public start(node: Node): void {}
    public stop(node: Node): void {}
    public update(node: Node, deltaTime: number): void {}

    public serialize(): any {
        return serializeProperties(this);
    }

    public deserialize(data: any) {
        deserializeProperties(this, data);
    }

    public abstract get inspectorProperties(): string[];
}

export class SpinAnimation extends Animation {
    public degreesPerSecond = new RangeProperty(-90, 90, 5);

    update(node: Node, deltaTime: number): void {
        const tn = get(node.transformNode);
        if (tn) {
            tn.rotate(
                BABYLON.Vector3.UpReadOnly,
                Util.radians(this.degreesPerSecond.get() * deltaTime),
                BABYLON.Space.WORLD);
        }
    }

    public get inspectorProperties() { return ['degreesPerSecond']; }
}

export class OrbitAnimation extends Animation {
    public target = new NodeProperty();
    public degreesPerSecond = new RangeProperty(-90, 90, 5);
    public distance = new RangeProperty(0, 4, 1);

    update(node: Node, deltaTime: number): void {
        const target = get(this.target.get()?.transformNode);
        const tn = get(node.transformNode);
        if (target && tn && target !== tn) {
            const dir = tn.position.subtract(target.position).normalize();
            tn.position = target.position.add(dir.scale(this.distance.get()));
            tn.rotateAround(target.position, BABYLON.Vector3.UpReadOnly, Util.radians(this.degreesPerSecond.get() * deltaTime));
        }
    }

    public get inspectorProperties() { return ['target', 'degreesPerSecond', 'distance']; }
}

export class BounceAnimation extends Animation {
    public height = new RangeProperty(0, 1, 0.1);
    public metersPerSecond = new RangeProperty(0, 1, 0.1);

    private cycle = 0;

    update(node: Node, deltaTime: number): void {
        const tn = get(node.transformNode);
        if (tn) {
            this.cycle += deltaTime;
            tn.position = node.position.toVector3().add(
                BABYLON.Vector3.UpReadOnly.scale(Math.sin(this.cycle) * this.height.get()));
        }
    }

    public get inspectorProperties() { return ['height', 'metersPerSecond']; }
}

export class PathAnimation extends Animation {
    public position = new Vector3Property();
    public rotation = new QuaternionProperty();
    public time = new RangeProperty(0.01, 100, 1);
    public easing = new OptionProperty([...Object.keys(EaseCurves)], 0, true);

    start(node: Node): void {
        const objectApi = api.get(node) as ObjectApi;
        objectApi.animateTo({
            position: this.position.get(),
            quaternion: this.rotation.get(),
            time: this.time.get(),
            easing: Object.values(EaseCurves)[this.easing.get()] });
    }

    stop(node: Node) {
        const objectApi = api.get(node) as ObjectApi;
        objectApi.stopAnimations();
    }

    public get inspectorProperties() { return ['position', 'rotation', 'time', 'easing']; }
}

export class FromModelAnimation extends Animation {
    public animation = new ModelAnimationProperty();
    public loop = new BoolProperty();

    start(node: Node): void {
        var grp = node.animationGroups.find(g => g.name === this.animation.get());
        if (grp) {
            grp.reset();
            grp.play(this.loop.get());
        }
    }

    stop(node: Node) {
        var grp = node.animationGroups.find(g => g.name === this.animation.get());
        grp?.pause();
    }

    public get inspectorProperties() { return ['animation', 'loop']; }
}

export const animationFactories = new Map<string, () => Animation>();
animationFactories.set(AnimationType.Spin, () => new SpinAnimation());
animationFactories.set(AnimationType.Orbit, () => new OrbitAnimation());
animationFactories.set(AnimationType.Bounce, () => new BounceAnimation());
animationFactories.set(AnimationType.Path, () => new PathAnimation());
animationFactories.set(AnimationType.FromModel, () => new FromModelAnimation());