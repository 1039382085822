import { Serializable, deserializeArray, serializeProperties, deserializeProperties } from "../util/serializable.js";
import { project } from '../services/app.js';
import * as Util from '../util/util.js';
import { AnimationProperty, AudioProperty, BoolProperty, NodeProperty, RangeProperty, SceneProperty, ScriptProperty, StringProperty } from "../models/property.js";
import { get } from 'svelte/store';
import { NodeFunction } from "../models/node.js";
import { array } from '../util/array.js';
import ObjectNode from "../models/objectNode.js";
import WaypointNode from '../models/waypointNode.js';

export class ActionEvent implements Serializable {
    public get senderId() { return this._senderId; }
    public get eventName() { return this._eventName; }
    constructor(private _senderId: string = '', private _eventName: string = '') {}

    public serialize(): any {
        return { eventName: this.eventName };
    }

    public deserialize(data: any) {
        this._eventName = data.eventName;
    }
}

export interface Condition extends Serializable {
    readonly type: string;
    isMet(event: ActionEvent): boolean;
}

export abstract class Step implements Serializable {
    public serialize(): any {
        const data = serializeProperties(this);
        data.type = this.type;
        return data;
    }

    public deserialize(data: any) {
        deserializeProperties(this, data);
    }

    public abstract execute(target: any): boolean;
    public abstract get type(): string;
    public abstract get inspectorProperties(): string[];
}

export const conditionFactories = new Map<string, () => Condition>();
export const stepFactories = new Map<string, () => Step>();

export class Action implements Serializable {
    public events: ActionEvent[] = [];
    public conditions: Condition[] = [];
    public steps = array<Step>([]);

    public serialize(): any {
        return {
            events: this.events.map(e => e.serialize()),
            conditions: this.conditions.map(e => e.serialize()),
            steps: this.steps.map(e => e.serialize()),
        };
    }

    public deserialize(data: any) {
        this.events = deserializeArray(data.events, e => new ActionEvent());

        this.conditions = deserializeArray(data.conditions, c => {
            const factory = conditionFactories.get(c.type);
            if (factory) {
                return factory();
            } else {
                throw new Error('Unknown action condition type: ' + c.type);
            }
        });

        this.steps.set(deserializeArray(data.steps, s => {
            const factory = stepFactories.get(s.type);
            if (factory) {
                return factory();
            } else {
                throw new Error('Unknown action step type: ' + s.type);
            }
        }));
    }
}

export enum StepTypes {
    GoToScene = 'go-to-scene',
    GoToNextScene = 'go-to-next-scene',
    GoToPreviousScene = 'go-to-previous-scene',
    GoToWaypoint = 'go-to-waypoint',
    SetEnabled = 'set-enabled',
    PlaySound = 'play-sound',
    StopSound = 'stop-sound',
    SetAnimation = 'set-animation',
    RunScript = "run-script",
    Delay = 'delay',
}

export class ActionManager implements Serializable {
    public actions: Action[] = [];
    private subscribers: (() => void)[] = [];

    constructor() {
        conditionFactories.clear();
        stepFactories.clear();

        conditionFactories.set(IsSenderType, () => new IsSenderCondition());
        stepFactories.set(StepTypes.GoToScene, () => new GoToSceneStep());
        stepFactories.set(StepTypes.GoToNextScene, () => new GoToNextSceneStep());
        stepFactories.set(StepTypes.GoToPreviousScene, () => new GoToPreviousSceneStep());
        stepFactories.set(StepTypes.GoToWaypoint, () => new GoToWaypointStep());
        stepFactories.set(StepTypes.SetEnabled, () => new SetEnabledStep());
        stepFactories.set(StepTypes.PlaySound, () => new PlaySoundStep());
        stepFactories.set(StepTypes.StopSound, () => new StopSoundStep());
        stepFactories.set(StepTypes.SetAnimation, () => new SetAnimationStep());
        stepFactories.set(StepTypes.RunScript, () => new RunScriptStep());
        stepFactories.set(StepTypes.Delay, () => new DelayStep());
    }

    public register(action: Action) {
        this.actions.push(action);
        this.subscribers.forEach(cb => cb());
    }

    public unregister(action: Action) {
        if (Util.remove(this.actions, action)) {
            this.subscribers.forEach(cb => cb());
        }
    }

    public subscribe(cb: () => void) {
        this.subscribers.push(cb);
        return () => Util.remove(this.subscribers, cb);
    }

    public dispatchEvent(senderId: string, target: any, eventName: string) {
        const event = new ActionEvent(senderId, eventName);
        for (const action of this.actions) {
            for (const actionEvent of action.events) {
                if (eventName == actionEvent.eventName) {
                    if (action.conditions.every(c => c.isMet(event))) {
                        this.execute(target, action.steps.get());
                    }
                }
            }
        }
    }

    public execute(target: any, sequence: Step[], startAt = 0) {
        for (let i = startAt; i < sequence.length; i++) {
            const step = sequence[i];
            if (!step.execute(target)) {
                break;
            } else if (step.type == StepTypes.Delay) {
                setTimeout(() => this.execute(target, sequence, i + 1), (step as DelayStep).seconds.get() * 1000);
                break;
            }
        }
    }

    public find(pred: (a: Action) => boolean) {
        return this.actions.find(pred);
    }

    public serialize() {
        return this.actions.filter(a => a.steps.length > 0).map(a => a.serialize());
    }

    public deserialize(data: any) {
        this.actions = deserializeArray(data, a => new Action());
    }
}

const IsSenderType = 'is-sender';
export class IsSenderCondition implements Condition {
    constructor(public senderId = '') {}
    public isMet(event: ActionEvent) { return event.senderId === this.senderId; }
    public get type() { return IsSenderType; }
    public serialize() { return { type: this.type, senderId: this.senderId }; }
    public deserialize(data: any) { this.senderId = data.senderId; }
}

export class GoToSceneStep extends Step {
    public scene = new SceneProperty();

    public execute() {
        get(project).transitionToScene(this.scene.getId());
        return false;
    }

    public get type() { return StepTypes.GoToScene; }
    public get inspectorProperties() { return ['scene']; }
}

export class GoToNextSceneStep extends Step {
    public execute() {
        const scenes = get(project).scenes;
        const current = scenes.indexOf(get(get(project).scene));
        const next = scenes.get()[(current + 1) % scenes.length];
        get(project).transitionToScene(next.id.get());
        return false;
    }

    public get type() { return StepTypes.GoToNextScene; }
    public get inspectorProperties(): string[] { return []; }
}

export class GoToPreviousSceneStep extends Step {
    public execute() {
        const scenes = get(project).scenes;
        const current = scenes.indexOf(get(get(project).scene));
        const next = scenes.get()[(scenes.length + current - 1) % scenes.length];
        get(project).transitionToScene(next.id.get());
        return false;
    }

    public get type() { return StepTypes.GoToPreviousScene; }
    public get inspectorProperties(): string[] { return []; }
}

export class GoToWaypointStep extends Step {
    public waypoint = new NodeProperty(n => n instanceof WaypointNode);

    public execute() {
        this.waypoint.get()?.callFunction(NodeFunction.GoToWaypoint);
        return true;
    }

    public get type() { return StepTypes.GoToWaypoint; }
    public get inspectorProperties() { return ['waypoint']; }
}

export class SetEnabledStep extends Step {
    public object = new NodeProperty();
    public enabled = new BoolProperty(true);

    public execute() {
        this.object.get()?.enabled.set(this.enabled.get());
        return true;
    }

    public get type() { return StepTypes.SetEnabled; }
    public get inspectorProperties() { return ['object', 'enabled']; }
}

export class PlaySoundStep extends Step {
    public object = new NodeProperty();
    public sound = new AudioProperty();
    public playFromBeginning = new BoolProperty(true);

    public execute() {
        const node = this.object.get();
        if (node instanceof ObjectNode) {
            node.sound.copyFrom(this.sound);

            if (this.playFromBeginning.get()) {
                node.callFunction(NodeFunction.PlaySoundFromBeginning);
            } else {
                node.callFunction(NodeFunction.ResumeSound);
            }
        }

        return true;
    }

    public get type() { return StepTypes.PlaySound; }
    public get inspectorProperties() { return ['object', 'sound', 'playFromBeginning']; }
}

export class StopSoundStep extends Step {
    public object = new NodeProperty();

    public execute() {
        this.object.get()?.callFunction(NodeFunction.StopSound);
        return true;
    }

    public get type() { return StepTypes.StopSound; }
    public get inspectorProperties() { return ['object']; }
}

export class SetAnimationStep extends Step {
    public object = new NodeProperty(n => n instanceof ObjectNode);
    public animation = new AnimationProperty();

    public execute() {
        const obj = this.object.get() as ObjectNode;
        if (obj) {
            obj.animation.get()?.stop(obj);
            obj.animation.copyFrom(this.animation);
            obj.animation.get()?.start(obj);
        }
        return true;
    }

    public get type() { return StepTypes.SetAnimation; }
    public get inspectorProperties() { return ['object', 'animation']; }
}

export class RunScriptStep extends Step {
    public script = new ScriptProperty();
    public arguments = new StringProperty();

    public execute(target: any) {
        this.script.get()?.invoke(target, this.arguments.get());
        return true;
    }

    public get type() { return StepTypes.RunScript; }
    public get inspectorProperties() { return ['script', 'arguments']; }
}

export class DelayStep extends Step {
    public seconds = new RangeProperty(0, 3600);

    public execute(target: any) { return true; }

    public get type() { return StepTypes.Delay; }
    public get inspectorProperties() { return ['seconds']; }
}