<script lang="ts">
    import user from "../services/user.js"
    import { smallScreen } from "../util/screen.js";
    import External from "./external.svelte";
    import Logo from "./logo.svelte"
    import Select from './select.svelte'

    const discord = "https://discord.gg/M5WuF85NWz";
    const studioUrl = "https://www.virtualmaker.dev";
</script>

<section id="nav" class="flex justify-spaced align-center w-100 sz-20 top">
    <div class="flex align-center sz-24 mt-5">
        <a href="/">
            <Logo />
        </a>
    </div>

    {#if $smallScreen}
        <Select image="menu.png" rightSide={true}>
            <a class="p-20 dark border border-color" href="/docs">Docs</a>
            <a class="p-20 dark border border-color" href="/demos">Demos</a>
            <!-- <a class="p-20 dark border border-color" href="/pricing">Pricing</a> -->
            <External source="nav" target="virtualmakerdev" cls="p-20 dark border border-color" href={studioUrl}>Studio</External>
            <External source="nav" target="discord" cls="p-20 dark border border-color" href={discord}>Discord</External>
            <a class="p-20 dark border border-color" href="/blog">Blog</a>
            {#if user.signedIn}
                <a class="p-20 dark border border-color" href="/account">Account</a>
                <button class="p-20 dark border border-color text-left sz-inherit" on:click={() => user.signOut()}>Sign out</button>
            {:else}
                <a class="p-20 dark border border-color" href="/login">Log In</a>
            {/if}
        </Select>
    {:else}
        <div class="flex align-center">
            <a class="primary-button m-5" href="/edit">Launch Editor</a>
            <a class="secondary-button m-5" href="/docs">Docs</a>
            <a class="secondary-button m-5" href="/demos">Demos</a>
            <External source="nav-mobile" target="virtualmakerdev" cls="secondary-button m-5" href={studioUrl}>Services</External>
            <!-- <a class="secondary-button m-5" href="/pricing">Pricing</a> -->
            <External source="nav-mobile" target="discord" cls="secondary-button m-5" href={discord}>Discord</External>
            <a class="secondary-button m-5" href="/blog">Blog</a>
            {#if user.signedIn}
                <div class="m-20">
                    <Select text={user.username} rightSide={true}>
                        <a href="/account" class="p-20 mt-5 dark border border-color text-left sz-inherit nowrap no-underline no-hover">Account</a>
                        <button class="p-20 dark border border-color text-left sz-inherit nowrap" on:click={() => user.signOut()}>Sign out</button>
                    </Select>
                </div>
            {:else}
                <a class="secondary-button m-5" href="/login">Log In</a>
            {/if}
        </div>
    {/if}
</section>

<style>
    .top {
        z-index: 7;
        position: relative;
    }

    .no-hover:hover {
        color: white;
    }

    .border-color {
        border-color: #cce1ff;
    }
</style>